<template>
  <div>
    <app-page-detail page-title="Detail CAPA" page-toolbar-title="Detail CAPA">
      <template #toolbar>
        <action-button :data-detail="data_detail" @refresh-detail="refreshDetail()"></action-button>
      </template>
      <v-row>
        <v-col cols="12" lg="9">
          <v-row>
            <v-col cols="12" md="12">
              <card-expansion title="General" class="mb-3" :loading="loading">
                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field dense readonly v-model="cpar_header.document_no_" placeholder="No." label="No." outlined hide-details class="mb-2"></v-text-field>
                    <v-text-field dense readonly v-model="cpar_header.document_date" placeholder="Doc. Date" label="Doc. Date" outlined hide-details class="mb-2"></v-text-field>
                    <v-text-field dense readonly v-model="cpar_header.area_code" placeholder="Area Code" label="Area Code" outlined hide-details class="mb-2"></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field dense readonly v-model="cpar_header.dept_name" placeholder="Department" label="Department" outlined hide-details class="mb-2"></v-text-field>
                    <v-text-field dense readonly v-model="cpar_header.cpar_source_code" placeholder="Sumber CPAR" label="Sumber CPAR" hide-details outlined class="mb-2"></v-text-field>
                  </v-col>
                </v-row>

                <v-divider class="mb-2"></v-divider>


                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field dense readonly v-model="cpar_header.company_name" placeholder="Nama Perusahaan" label="Nama Perusahaan" outlined hide-details class="mb-2"></v-text-field>
                    <v-text-field dense readonly v-model="cpar_header.company_project" placeholder="Nama Proyek" label="Nama Proyek" outlined hide-details class="mb-2"></v-text-field>
                    <v-text-field dense readonly v-model="cpar_header.sales_order_no_" placeholder="Sales Order No." label="Sales Order No." outlined hide-details class="mb-2"></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field dense readonly v-model="cpar_header.location" placeholder="Lokasi" label="Lokasi" outlined hide-details class="mb-2"></v-text-field>
                    <v-select label="Jenis CPAR" v-model="cpar_header.cpar_type" :items="cpar_types" item-text="text" item-value="value" return-object hide-details outlined dense class="mb-2" readonly></v-select>
                    <v-text-field dense readonly v-model="cpar_header.ref_doc_no_" placeholder="Ref Doc No." label="Doc. No." outlined hide-details class="mb-2"></v-text-field>
                  </v-col>
                </v-row>

                <v-row class="mb-3">
                  <v-col cols="12" md="6">
                    <v-text-field dense v-model="cpar_header.initiator" placeholder="Pemrakarsa" label="Pemrakarsa" readonly outlined hide-details class="mb-2"></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field dense readonly v-model="cpar_category_active.name" placeholder="Kategori" label="Kategori" outlined hide-details class="mb-2"></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <span class="text-md-body-1 mb-2 d-block">Ditujukan kepada: </span>
                    <v-sheet elevation="1" outlined>
                      <v-simple-table dense outlined>
                        <thead>
                          <tr>
                            <th>No</th>
                            <th>Subject</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(sip, i) in cpar_subject" :key="i">
                            <td>{{ i + 1 }}</td>
                            <td>{{ sip.description }} / {{ sip.code }}<br>{{ sip.source }}</td>
                          </tr>
                        </tbody>
                      </v-simple-table>
                    </v-sheet>
                  </v-col>
                </v-row>

                <v-row class="mb-3">
                  <v-col cols="6" md="6">
                    <v-text-field dense readonly v-model="cpar_header.delivery_date" placeholder="Delivery Date" label="Delivery Date" type="date" outlined hide-details class="mb-2"></v-text-field>
                    <v-text-field dense readonly v-model="cpar_header.art_number" placeholder="Art. Number" label="Art. Number" outlined hide-details class="mb-2"></v-text-field>
                    <v-text-field dense readonly v-model="cpar_header.uom" placeholder="Satuan" label="Satuan" outlined hide-details class="mb-2"></v-text-field>
                  </v-col>
                  <v-col cols="6" md="6">
                    <v-text-field dense readonly v-model="cpar_header.art_number" placeholder="Model / Produk" label="Model / Produk" outlined hide-details class="mb-2"></v-text-field>
                    <v-text-field dense readonly v-model="cpar_header.quantity" placeholder="Jumlah" label="Jumlah" outlined hide-details class="mb-2"></v-text-field>
                  </v-col>
                </v-row>
              </card-expansion>
              <card-expansion title="Detail" class="mb-3" :loading="loading">
                <v-row>
                  <v-col>
                    <v-toolbar dense elevation="1" outlined>
                      <v-tabs v-model="tab" show-arrows>
                        <v-tab>1. Description<br />&nbsp;</v-tab>
                        <v-tab>2. Identification<br />&nbsp;</v-tab>
                        <v-tab>3. Root Cause Analysist <br />Fishbone Diagram</v-tab>
                        <v-tab>4. Preventive, Correction <br />& Corrective Action</v-tab>
                      </v-tabs>
                    </v-toolbar>
                  </v-col>
                </v-row>

                <v-tabs-items v-model="tab">
                  <!-- 1. Description -->
                  <v-tab-item class="px-1">
                    <v-row class="mb-3 mt-1">
                      <v-col>
                        <v-card elevation="1" class="mt-1">
                          <v-data-table :items-per-page="100" hide-default-footer :items="cpar_description" :headers="cpar_description_columns" item-key="line_no_"> </v-data-table>
                        </v-card>
                      </v-col>
                    </v-row>
                    <v-row class="mb-3">
                      <v-col cols="12" md="12">
                        <v-textarea dense readonly v-model="cpar_header.annotation" placeholder="Keterangan" label="Keterangan" outlined hide-details class="mb-2"></v-textarea>
                      </v-col>
                    </v-row>
                    <div class="subtitle-1">Attachments</div>
                    <v-row class="mb-3">
                      <v-col cols="12" md="12">
                        <a :href="cpar_header.attachment1" v-if="cpar_header.attachment1 !== ''" :title="cpar_header.attachment_type1" target="_blank" class="d-block"><v-icon>mdi-attachment</v-icon> {{ cpar_header.attachment_type1 }}</a>
                        <a :href="cpar_header.attachment2" v-if="cpar_header.attachment2 !== ''" :title="cpar_header.attachment_type2" target="_blank" class="d-block"><v-icon>mdi-attachment</v-icon> {{ cpar_header.attachment_type2 }}</a>
                        <a :href="cpar_header.attachment3" v-if="cpar_header.attachment3 !== ''" :title="cpar_header.attachment_type3" target="_blank" class="d-block"><v-icon>mdi-attachment</v-icon> {{ cpar_header.attachment_type3 }}</a>
                        <a :href="cpar_header.attachment4" v-if="cpar_header.attachment4 !== ''" :title="cpar_header.attachment_type4" target="_blank" class="d-block"><v-icon>mdi-attachment</v-icon> {{ cpar_header.attachment_type4 }}</a>
                        <a :href="cpar_header.attachment5" v-if="cpar_header.attachment5 !== ''" :title="cpar_header.attachment_type5" target="_blank" class="d-block"><v-icon>mdi-attachment</v-icon> {{ cpar_header.attachment_type5 }}</a>
                      </v-col>
                    </v-row>
                  </v-tab-item>
                  <!-- 2. Identification -->
                  <v-tab-item class="px-1">
                    <v-row class="mb-3 mt-1">
                      <v-col cols="12">
                        <div>
                          <v-img :src="require('@/assets/images/fishbone.png')" class="center mb-4" height="240" contain></v-img>
                        </div>
                      </v-col>
                    </v-row>
                  </v-tab-item>
                  <!-- 3. Root Cause Analysist Fishbone Diagram -->
                  <v-tab-item class="px-1">
                    <v-row class="mb-3 mt-1">
                      <v-col cols="12">
                        <v-sheet elevation="1" outlined class="mb-2">
                          <h3 class="mx-4 my-2">A. METHOD</h3>
                          <v-data-table dense :items-per-page="100" hide-default-footer :items="cpar_root_cause_a" :headers="cpar_root_cause_a_columns">
                            <template v-slot:[`item.line_no_`]="{ item }">
                              <div style="min-width: 20px; max-width: 20px; white-space: normal">
                                <div style="white-space: break-spaces">{{ item.line_no_ }}</div>
                              </div>
                            </template>
                            <template v-slot:[`item.description`]="{ item }">
                              <div style="min-width: 400px; max-width: 600px; white-space: normal">
                                <div style="white-space: break-spaces">{{ item.description }}</div>
                              </div>
                            </template>
                            <template v-slot:[`item.attachment`]="{ item }">
                              <v-btn :href="item.attachment" target="_blank" icon text x-small v-if="item.attachment !== '' && item.attachment !== 'null'" title="Clict to download">
                                <v-icon>mdi-attachment</v-icon>
                              </v-btn>
                            </template>
                          </v-data-table>
                        </v-sheet>
                        <v-divider></v-divider>
                        <v-sheet elevation="1" outlined class="mb-2">
                          <h3 class="mx-4 my-2">B. MAN</h3>
                          <v-data-table dense :items-per-page="100" hide-default-footer :items="cpar_root_cause_b" :headers="cpar_root_cause_b_columns">
                            <template v-slot:[`item.line_no_`]="{ item }">
                              <div style="min-width: 20px; max-width: 20px; white-space: normal">
                                <div style="white-space: break-spaces">{{ item.line_no_ }}</div>
                              </div>
                            </template>
                            <template v-slot:[`item.description`]="{ item }">
                              <div style="min-width: 400px; max-width: 600px; white-space: normal">
                                <div style="white-space: break-spaces">{{ item.description }}</div>
                              </div>
                            </template>
                            <template v-slot:[`item.attachment`]="{ item }">
                              <v-btn :href="item.attachment" target="_blank" icon text x-small v-if="item.attachment !== '' && item.attachment !== 'null'" title="Clict to download">
                                <v-icon>mdi-attachment</v-icon>
                              </v-btn>
                            </template>
                          </v-data-table>
                        </v-sheet>
                        <v-divider></v-divider>
                        <v-sheet elevation="1" outlined class="mb-2">
                          <h3 class="mx-4 my-2">C. MACHINE</h3>
                          <v-data-table dense :items-per-page="100" hide-default-footer :items="cpar_root_cause_c" :headers="cpar_root_cause_c_columns">
                            <template v-slot:[`item.line_no_`]="{ item }">
                              <div style="min-width: 20px; max-width: 20px; white-space: normal">
                                <div style="white-space: break-spaces">{{ item.line_no_ }}</div>
                              </div>
                            </template>
                            <template v-slot:[`item.description`]="{ item }">
                              <div style="min-width: 400px; max-width: 600px; white-space: normal">
                                <div style="white-space: break-spaces">{{ item.description }}</div>
                              </div>
                            </template>
                            <template v-slot:[`item.attachment`]="{ item }">
                              <v-btn :href="item.attachment" target="_blank" icon text x-small v-if="item.attachment !== '' && item.attachment !== 'null'" title="Clict to download">
                                <v-icon>mdi-attachment</v-icon>
                              </v-btn>
                            </template>
                          </v-data-table>
                        </v-sheet>
                        <v-divider></v-divider>
                        <v-sheet elevation="1" outlined class="mb-2">
                          <h3 class="mx-4 my-2">D. MATERIAL</h3>
                          <v-data-table dense :items-per-page="100" hide-default-footer :items="cpar_root_cause_d" :headers="cpar_root_cause_d_columns">
                            <template v-slot:[`item.line_no_`]="{ item }">
                              <div style="min-width: 20px; max-width: 20px; white-space: normal">
                                <div style="white-space: break-spaces">{{ item.line_no_ }}</div>
                              </div>
                            </template>
                            <template v-slot:[`item.description`]="{ item }">
                              <div style="min-width: 400px; max-width: 600px; white-space: normal">
                                <div style="white-space: break-spaces">{{ item.description }}</div>
                              </div>
                            </template>
                            <template v-slot:[`item.attachment`]="{ item }">
                              <v-btn :href="item.attachment" target="_blank" icon text x-small v-if="item.attachment !== '' && item.attachment !== 'null'" title="Clict to download">
                                <v-icon>mdi-attachment</v-icon>
                              </v-btn>
                            </template>
                          </v-data-table>
                        </v-sheet>
                        <v-divider></v-divider>
                        <v-sheet elevation="1" outlined class="mb-2">
                          <h3 class="mx-4 my-2">E. ENVIRONMENTAL</h3>
                          <v-data-table dense :items-per-page="100" hide-default-footer :items="cpar_root_cause_e" :headers="cpar_root_cause_e_columns">
                            <template v-slot:[`item.line_no_`]="{ item }">
                              <div style="min-width: 20px; max-width: 20px; white-space: normal">
                                <div style="white-space: break-spaces">{{ item.line_no_ }}</div>
                              </div>
                            </template>
                            <template v-slot:[`item.description`]="{ item }">
                              <div style="min-width: 400px; max-width: 600px; white-space: normal">
                                <div style="white-space: break-spaces">{{ item.description }}</div>
                              </div>
                            </template>
                            <template v-slot:[`item.attachment`]="{ item }">
                              <v-btn :href="item.attachment" target="_blank" icon text x-small v-if="item.attachment !== '' && item.attachment !== 'null'" title="Clict to download">
                                <v-icon>mdi-attachment</v-icon>
                              </v-btn>
                            </template>
                          </v-data-table>
                        </v-sheet>
                      </v-col>
                    </v-row>
                  </v-tab-item>
                  <!-- 4. Preventive, Correction & Corrective Action -->
                  <v-tab-item class="px-1">
                    <v-row class="mb-3 mt-1">
                      <v-col cols="12">
                        <v-sheet elevation="1" outlined class="mb-2">
                          <h3 class="mx-4 my-2">CORRECTION / PERBAIKAN</h3>
                          <v-data-table :items-per-page="100" hide-default-footer :items="cpar_correction" :headers="cpar_correction_columns">
                            <template v-slot:[`item.line_no_`]="{ item }">
                              <div style="min-width: 20px; max-width: 20px; white-space: normal">
                                <div style="white-space: break-spaces">{{ item.line_no_ }}</div>
                              </div>
                            </template>
                            <template v-slot:[`item.description`]="{ item }">
                              <div style="min-width: 400px; max-width: 600px; white-space: normal">
                                <div style="white-space: break-spaces">{{ item.description }}</div>
                              </div>
                            </template>
                            <template v-slot:[`item.attachment`]="{ item }">
                              <v-btn :href="item.attachment" target="_blank" icon text x-small v-if="item.attachment !== '' && item.attachment !== 'null'" title="Clict to download">
                                <v-icon>mdi-attachment</v-icon>
                              </v-btn>
                            </template>
                          </v-data-table>
                        </v-sheet>
                      </v-col>
                    </v-row>
                    <v-row class="mb-3 mt-1">
                      <v-col cols="12">
                        <v-sheet elevation="1" outlined class="mb-2">
                          <h3 class="mx-4 my-2">CORRECTIVE & PREVENTIVE ACTION / PENCEGAHAN</h3>
                          <v-data-table :items-per-page="100" hide-default-footer :items="cpar_corrective" :headers="cpar_corrective_columns">
                            <template v-slot:[`item.line_no_`]="{ item }">
                              <div style="min-width: 20px; max-width: 20px; white-space: normal">
                                <div style="white-space: break-spaces">{{ item.line_no_ }}</div>
                              </div>
                            </template>
                            <template v-slot:[`item.description`]="{ item }">
                              <div style="min-width: 400px; max-width: 600px; white-space: normal">
                                <div style="white-space: break-spaces">{{ item.description }}</div>
                              </div>
                            </template>
                            <template v-slot:[`item.attachment`]="{ item }">
                              <v-btn :href="item.attachment" target="_blank" icon text x-small v-if="item.attachment !== '' && item.attachment !== 'null'" title="Clict to download">
                                <v-icon>mdi-attachment</v-icon>
                              </v-btn>
                            </template>
                          </v-data-table>
                        </v-sheet>
                      </v-col>
                    </v-row>
                  </v-tab-item>
                </v-tabs-items>
              </card-expansion>

              <card-expansion title="Verifikasi" class="mb-3">
                <v-row>
                  <v-col cols="12" md="6">
                    <v-textarea dense readonly v-model="cpar_header.verification" placeholder="Verifikasi" label="Verifikasi" hide-details outlined class="mb-2"></v-textarea>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field dense readonly v-model="cpar_header.verification_date" placeholder="Tanggal Verifikasi" label="Tanggal Verifikasi" outlined hide-details class="mb-2"></v-text-field>
                    <v-text-field dense readonly v-model="cpar_header.verification_by_username" placeholder="Di Verifikasi Oleh" label="Di Verifikasi Oleh" outlined hide-details class="mb-2"></v-text-field>
                  </v-col>
                </v-row>
              </card-expansion>
              <card-expansion title="List PIC" class="mb-3">
                <v-row>
                  <v-col cols="12">
                    <CparPicList v-bind:data-list="cpar_pic"></CparPicList>
                  </v-col>
                </v-row>
              </card-expansion>
              <card-expansion title="List Approval" class="mb-3">
                <v-row>
                  <v-col cols="12">
                    <ApprovalList v-bind:data-list="cpar_approval"></ApprovalList>
                  </v-col>
                </v-row>
              </card-expansion>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" lg="3">
          <document-info :cpar_header="cpar_header"></document-info>
          <app-comment :document_id="parseInt(cpar_header.id)" :source_document="cpar_header.source_document" :document_no_="cpar_header.document_no_"></app-comment>
        </v-col>
      </v-row>
    </app-page-detail>


    <!-- <page-title :title="cpar_header.document_no_">
        <template slot="action">
          <rightbar title="View Comments" hideToolbar class="d-inline">
            <template v-slot:activator="{ on }">
              <v-icon class="ms-2" v-on:click="on">mdi-comment</v-icon>
            </template>
            <CommentList :cpar_header_id="id" :elevation="1"></CommentList>
          </rightbar>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon class="ms-2" title="Refresh" v-bind="attrs" v-on="on" @click="refreshDetail()">mdi-refresh</v-icon>
            </template>
            <span>Refresh</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="light" v-bind="attrs" v-on="on" icon @click="printDocument()">
                <v-icon>mdi-printer</v-icon>
              </v-btn>
            </template>
            <span>Print</span>
          </v-tooltip>
        </template>
      </page-title> -->

    <!-- <v-toolbar dense class="mb-2">
          <v-btn small class="ml-n1 mx-2" color="primary" v-if="!loading && showDeleteBtn">Delete</v-btn>
          <v-btn small class="ml-n1 mx-2" color="primary" v-if="!loading && showReopenBtn" @click="reopenData()" :loading="loading_reopen">Re-Open</v-btn>
          <v-btn small class="ml-n1 mx-2" color="primary" v-if="!loading && showCancelBtn" @click="cancelData()" :loading="loading_cancel">Cancel</v-btn>
          <v-btn small class="ml-n1 mx-2" color="primary" v-if="!loading && showSendApprovalBtn" @click="sendApproval()" :loading="loading_send_approval">Send Approval</v-btn>
          <v-btn small class="ml-n1 mx-2" color="primary" v-if="!loading && showApproveBtn" @click="approveData()" :loading="loading_approve">Approve</v-btn>
          <v-btn small class="ml-n1 mx-2" color="primary" v-if="!loading && showEditBtn" @click="redirect('Administration.CPAR.Edit', { id: id })">Edit</v-btn>
          <v-btn small class="ml-n1 mx-2" color="primary" v-if="!loading && showSetPicBtn" @click="redirect('Administration.CPAR.SetPIC', { id: id })">Set PIC</v-btn>
          <set-close-document :id="parseInt(id)" @callback="getDetail(true)" v-if="!loading && showCloseBtn">
            <template v-slot:activator="{ on }">
              <v-btn small class="ml-n1 mx-2" color="primary" v-on:click="on">Close Document</v-btn>
            </template>
          </set-close-document>
          <v-spacer></v-spacer>
          <v-btn small class="ml-n1" @click="$router.go(-1)">Back</v-btn>
        </v-toolbar> -->

  </div>
</template>

<script>
// import CommentList from "./components/CommentList.vue";
import ApprovalList from "./components/ApprovalList.vue";
import CparPicList from "./components/CparPicList.vue";
import DocumentInfo from './components/DocumentInfo.vue';
import ActionButton from './components/ActionButton.vue';
// import SetCloseDocument from "./components/SetCloseDocument.vue";

export default {
  components: {
    // CommentList,
    ApprovalList,
    CparPicList,
    DocumentInfo,
    ActionButton,
    // SetCloseDocument,
  },
  data() {
    return {
      id: null,
      loading: false,
      loading_send_approval: false,
      loading_reopen: false,
      loading_approve: false,
      loading_cancel: false,
      dialog: false,
      modal1: false,
      tab: null,
      data_detail: {},
      cpar_header: {},
      user: {},
      cpar_description_columns: [
        { text: "No.", value: "line_no_", sortable: false },
        { text: "Deskripsi", value: "description", sortable: false },
        { text: "Klausul", value: "clause", sortable: false },
        { text: "Kategori Temuan", value: "finding_category", sortable: false },
      ],
      cpar_description: [],

      cpar_identification_columns: [
        { text: "Attachment", value: "title", sortable: false },
        { text: "", value: "id", sortable: false, align: "right" },
      ],
      cpar_identification: [],

      cpar_root_cause_a_columns: [
        { text: "No.", value: "line_no_", sortable: false },
        { text: "Description", value: "description", sortable: false },
        { text: "Attachment", value: "attachment", sortable: false },
        { text: "PIC", value: "username", sortable: false },
        { text: "Tanggal", value: "action_date", sortable: false },
      ],
      cpar_root_cause_a: [],

      cpar_root_cause_b_columns: [
        { text: "No.", value: "line_no_", sortable: false },
        { text: "Description", value: "description", sortable: false },
        { text: "Attachment", value: "attachment", sortable: false },
        { text: "PIC", value: "username", sortable: false },
        { text: "Tanggal", value: "action_date", sortable: false },
      ],
      cpar_root_cause_b: [],

      cpar_root_cause_c_columns: [
        { text: "No.", value: "line_no_", sortable: false },
        { text: "Description", value: "description", sortable: false },
        { text: "Attachment", value: "attachment", sortable: false },
        { text: "PIC", value: "username", sortable: false },
        { text: "Tanggal", value: "action_date", sortable: false },
      ],
      cpar_root_cause_c: [],

      cpar_root_cause_d_columns: [
        { text: "No.", value: "line_no_", sortable: false },
        { text: "Description", value: "description", sortable: false },
        { text: "Attachment", value: "attachment", sortable: false },
        { text: "PIC", value: "username", sortable: false },
        { text: "Tanggal", value: "action_date", sortable: false },
      ],
      cpar_root_cause_d: [],

      cpar_root_cause_e_columns: [
        { text: "No.", value: "line_no_", sortable: false },
        { text: "Description", value: "description", sortable: false },
        { text: "Attachment", value: "attachment", sortable: false },
        { text: "PIC", value: "username", sortable: false },
        { text: "Tanggal", value: "action_date", sortable: false },
      ],
      cpar_root_cause_e: [],

      cpar_correction_columns: [
        { text: "No.", value: "line_no_", sortable: false },
        { text: "Description", value: "description", sortable: false },
        { text: "Attachment", value: "attachment", sortable: false },
        { text: "PIC", value: "username", sortable: false },
        { text: "Tanggal", value: "action_date", sortable: false },
      ],
      cpar_correction: [],

      cpar_corrective_columns: [
        { text: "No.", value: "line_no_", sortable: false },
        { text: "Description", value: "description", sortable: false },
        { text: "Attachment", value: "attachment", sortable: false },
        { text: "PIC", value: "username", sortable: false },
        { text: "Tanggal", value: "action_date", sortable: false },
      ],
      cpar_corrective: [],

      cpar_approval_columns: [
        { text: "Approval", value: "approval_label", sortable: false },
        { text: "User", value: "username", sortable: false },
        { text: "Approval Status", value: "approved", sortable: false },
        { text: "Approve Date", value: "approved_date", sortable: false },
      ],
      cpar_approval: [],

      subject: [],
      selected_subjects: [],
      cpar_categories: [],
      selected_cpar_categories: [],
      cpar_subject: [],
      cpar_pic: [],

      cpar_types: [
        { text: "", value: '' },
        { text: "Baru", value: 'N' },
        { text: "Berulang", value: 'R' },
      ],
    };
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    cpar_subject(n, o) {
      this.selected_subjects = [];

      n.forEach((item) => {
        this.selected_subjects.push(item.username);
      });
    },
  },
  computed: {
    dtId() {
      return parseInt(this.$route.query.id);
    },
    cpar_category_active() {
      var res = {};
      this.cpar_categories.forEach((item) => {
        if (this.cpar_header.cpar_category_id == item.id) {
          res = item;
        }
      });
      return res;
    },
    showDeleteBtn() {
      if (this.inArray(this.cpar_header.status, ["closed", "cancel"])) return false;

      if (this.cpar_header.created_by_username !== this.$store.state.loginInfo.username) return false;

      if (this.modulePermission("cpar", "delete") && !this.inArray(this.cpar_header.status, ["approved", "closed"]) && this.inArray(this.cpar_header.status, ["open"])) {
        return true;
      }
      return false;
    },
    showReopenBtn() {
      if (this.inArray(this.cpar_header.status, ["closed", "cancel"])) return false;

      if (this.cpar_header.created_by_username !== this.$store.state.loginInfo.username) return false;

      if (this.modulePermission("cpar", "reopen") && !this.inArray(this.cpar_header.status, ["approved"]) && !this.inArray(this.cpar_header.status, ["open"])) {
        return true;
      }
      return false;
    },
    showCancelBtn() {
      if (this.inArray(this.cpar_header.status, ["closed", "cancel"])) return false;

      if (this.modulePermission("cpar", "cancel") && !this.inArray(this.cpar_header.status, ["approved"]) && !this.inArray(this.cpar_header.status, ["cancel"])) {
        return true;
      }
      return false;
    },
    showSendApprovalBtn() {
      if (this.inArray(this.cpar_header.status, ["closed", "cancel"])) return false;

      if (this.modulePermission("cpar", "send-approval") && this.cpar_header.status == "open") {
        return true;
      }
      return false;
    },
    showApproveBtn() {
      if (!this.inArray(this.cpar_header.status, ["waiting-approval"])) return false;

      if (this.cpar_header.status == "waiting-approval") {
        let ap = false;
        if (typeof this.cpar_approval !== "undefined") {
          if (this.cpar_approval.length >= 0) {
            this.cpar_approval.forEach((item) => {
              let approved = parseInt(item.approved);
              if (approved == 0) {
                if (item.username == this.$store.state.loginInfo.username) {
                  ap = true;
                }
              }
            });
          }
        }

        return ap;
      }
      return false;
    },
    showEditBtn() {
      if (this.inArray(this.cpar_header.status, ["closed", "cancel"])) return false;

      if (this.modulePermission("cpar", "modify") && this.cpar_header.status == "open") {
        return true;
      }
      return false;
    },
    showCloseBtn() {
      if (!this.inArray(this.cpar_header.status, ["approved"])) return false;

      if (!this.inArray(this.cpar_header.substatus, ["", "on-process"])) return false;

      if (this.modulePermission("cpar", "close")) {
        return true;
      }

      return false;
    },
    showSetPicBtn() {
      if (!this.inArray(this.cpar_header.status, ["approved"])) return false;

      if (this.modulePermission("cpar", "set-pic") && this.cpar_header.status == "approved") {
        return true;
      }
      return false;
    },
  },
  methods: {
    async getCPARSources(refresh = false) {
      if (refresh == true) this.AxiosStorageRemove("GET", "cpar/sources");
      await this.$axios
        .get("cpar/sources", {
          params: {
            document_type: this.cpar_header.document_type,
          },
        })
        .then((res) => {
          this.cpar_sources = res.data.data.results;
        });
    },

    async getIntendedPerson(refresh = false) {
      if (refresh == true) this.AxiosStorageRemove("GET", "cpar/intended-person");
      await this.$axios.get("cpar/intended-person").then((res) => {
        this.subject = res.data.data.results;
      });
    },

    async getCategories(refresh = false) {
      if (refresh == true) this.AxiosStorageRemove("GET", "cpar/categories");
      await this.$axios.get("cpar/categories").then((res) => {
        this.cpar_categories = res.data.data.results;
      });
    },

    refreshDetail() {
      this.getDetail(true);
    },

    // showDialog() {
    //   this.getDetail(true);
    // },

    /**
     * getDetail
     * Method untuk mengambil data detail dokumen
     */
    async getDetail(refresh = false) {
      this.loading = true;
      this.notFound = false;
      this.showLoadingOverlay(true);

      if (refresh == true) this.AxiosStorageRemove("GET", "cpar/detail");

      this.showLoadingOverlay(true);

      this.cpar_root_cause_a = [];
      this.cpar_root_cause_b = [];
      this.cpar_root_cause_c = [];
      this.cpar_root_cause_d = [];
      this.cpar_root_cause_e = [];

      var config = {
        params: {
          id: this.dtId,
        },
        cacheConfig: true,
      };
      await this.$axios
        .get("cpar/detail", config)
        .then((res) => {
          this.data_detail = res.data.data;
          this.cpar_header = res.data.data.cpar_header;
          this.cpar_description = res.data.data.cpar_description;
          this.cpar_subject = res.data.data.cpar_subject;
          this.cpar_analysis = res.data.data.cpar_analysis;
          this.cpar_approval = res.data.data.cpar_approval;
          this.cpar_correction = res.data.data.cpar_correction;
          this.cpar_corrective = res.data.data.cpar_corrective;
          this.cpar_pic = res.data.data.cpar_pic;
          this.cpar_subject = res.data.data.cpar_subject;

          this.cpar_analysis.forEach((item) => {
            if (item.category_code == "A") {
              this.cpar_root_cause_a.push(item);
            } else if (item.category_code == "B") {
              this.cpar_root_cause_b.push(item);
            } else if (item.category_code == "C") {
              this.cpar_root_cause_c.push(item);
            } else if (item.category_code == "D") {
              this.cpar_root_cause_d.push(item);
            } else if (item.category_code == "E") {
              this.cpar_root_cause_e.push(item);
            }
          });

          this.showLoadingOverlay(false);

          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.showLoadingOverlay(false);
          this.axiosErrorHandler(error);
        });
    },

    /**
     * reopenData
     * Method untuk meng-approved dokumen
     * Dokumen harus berstatus Approved
     */
    async reopenData() {
      if (!confirm("Re-open document?")) return;
      this.loading_reopen = true;
      this.showLoadingOverlay(true);
      var formData = new FormData();
      formData.append("id", this.cpar_header.id);
      await this.$axios
        .post("cpar/reopen", formData)
        .then((res) => {
          this.loading_reopen = false;
          this.showLoadingOverlay(false);
          this.showAlert(res.data.status, res.data.message);

          this.refreshDetail();
        })
        .catch((error) => {
          this.loading_reopen = false;
          this.showLoadingOverlay(false);
          this.axiosErrorHandler(error);
        });
    },



    /**
     * approveData
     * Method untuk meng-approved dokumen
     * Dokumen harus berstatus Waiting Approval
     */
    approveData() {
      let post = async () => {
        this.loading_approve = true;
        this.showLoadingOverlay(true);
        var formData = new FormData();
        formData.append("id", this.cpar_header.id);
        await this.$axios
          .post("cpar/approve", formData)
          .then((res) => {
            this.loading_approve = false;
            this.showLoadingOverlay(false);
            this.showAlert(res.data.status, res.data.message);

            this.refreshDetail();
          })
          .catch((error) => {
            this.loading_approve = false;
            this.showLoadingOverlay(false);
            this.axiosErrorHandler(error);
          });
      };
      this.showConfirm("Confirm", "Approve document?", post);
    },

    /**
     * cancelData
     * Method untuk meng-cancel dokumen
     * Dokumen harus berstatus Open
     */
    cancelData() {
      let post = async () => {

        this.loading_cancel = true;
        this.showLoadingOverlay(true);
        var formData = new FormData();
        formData.append("id", this.cpar_header.id);
        await this.$axios
          .post("cpar/cancel", formData)
          .then((res) => {
            this.loading_cancel = false;
            this.showLoadingOverlay(false);
            this.showAlert(res.data.status, res.data.message);

            this.refreshDetail();
          })
          .catch((error) => {
            this.loading_cancel = false;
            this.showLoadingOverlay(false);
            this.axiosErrorHandler(error);
          });
      }
      this.showConfirm("Confirm", "Cancel document?", post);
    },

    /**
     * deleteData
     * Method untuk menghapus dokumen
     * Dokumen harus berstatus Cancel
     */
    deleteData() {
      let post = async () => {
        this.loading_cancel = true;
        this.showLoadingOverlay(true);
        var formData = new FormData();
        formData.append("id", this.cpar_header.id);
        await this.$axios
          .post("cpar/delete", formData)
          .then((res) => {
            this.loading_cancel = false;
            this.showLoadingOverlay(false);
            this.showAlert(res.data.status, res.data.message);

            this.refreshDetail();
          })
          .catch((error) => {
            this.loading_cancel = false;
            this.showLoadingOverlay(false);
            this.axiosErrorHandler(error);
          });
      }
      this.showConfirm("Confirm", "Cancel document?", post);
    },

    /**
     * sendApproval
     * Method untuk mengirimkan Approval
     * Dokumen harus berstatus Open
     */
    sendApproval() {
      let post = async () => {
        if (!confirm("Send Approval document?")) return;
        this.loading_reopen = true;
        this.showLoadingOverlay(true);
        var formData = new FormData();
        formData.append("id", this.cpar_header.id);
        await this.$axios
          .post("cpar/send-approval", formData)
          .then((res) => {
            this.loading_reopen = false;
            this.showLoadingOverlay(false);
            this.showAlert(res.data.status, res.data.message);

            this.refreshDetail();
          })
          .catch((error) => {
            this.loading_reopen = false;
            this.showLoadingOverlay(false);
            this.axiosErrorHandler(error);
          });
      }
      this.showConfirm("Confirm", "Send Approval?", post);
    },
  },

  beforeMount() {
    if (typeof this.$route.query.id !== "undefined") {
      this.id = parseInt(this.$route.query.id);
    } else {
      this.id = parseInt(this.$route.params.id);
    }
  },

  mounted() {
    this.getDetail(true);
    this.getIntendedPerson();
    this.getCategories();
  },
};
</script>
<style scoped>
.v-data-table>.v-data-table__wrapper>table>tbody>tr>td {
  vertical-align: top;
  padding-top: 5px;
}
</style>
